<template>
  <div
    class="masonry-item_type_quote"
  >
    <div
      :id="`masonry-item-${itemId}`"
      class="masonry-content white d-flex flex-column align-center pa-5"
    >
      <q
        class="text-center masonry-item__content font-weight-medium"
        :class="{'mb-10': item.content.author || item.content.source}"
      >
        {{ getExecuteStr(item.content.quote) }}
      </q>
      <h4 v-if="item.content.author" class="masonry-item__author font-weight-regular"> {{ getExecuteStr(item.content.author) }}</h4>
      <span v-if="item.content.source" class="masonry-item__source">{{ getExecuteStr(item.content.source) }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemId: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  name: 'ItemTypeQuote',
  mounted() {
    this.$emit('resourceLoaded')
  },
  methods: {
    getExecuteStr(str) {
      return window.tp.execute(str)
    }
  }
}
</script>